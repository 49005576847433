import { createSelector } from '@ngrx/store';
import { ActionTypes, TypeAction } from './error.actions';
import { IRootState } from '../../store';

export interface State {
  loginError: string;
}

const initialState = {
  loginError: ''
};

export function errorReducer(state: State = initialState, action: TypeAction): State {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_LOGIN_ERROR_MESSAGE: {
      return Object.assign({}, state, { loginError: payload });
    }
    default: {
      return state;
    }
  }
}

export const getLoginErrorMessage = createSelector(
  (rootState: IRootState) => {
    return rootState.errorPage;
  },
  (state: State) => {
    return state.loginError;
  }
);
